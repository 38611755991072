
<template>
   <div class="sb-page_intro mb-[5rem] lg:mb-[3rem] xl:mb-[0rem]" :class="layout_classes"  v-editable="editing && blok" ref="animation_target">
      <div class="sb-page_intro__inner">
         <span v-if="blok.label" class="sb-page_intro__label lg:mb-[2rem]">{{blok.label}}</span>
         <div  v-if="blok.headline" class="sb-page_intro__headline my-[1.5rem] lg:mb-[3rem] " v-html="renderRichText(blok.headline)"></div>
         <div v-if="blok.body" v-html="renderRichText(blok.body)" class="sb-page_intro__body lg:my-[3rem]"></div>
         <!-- <a v-if="blok.cta" href="">{{blok.cta}}</a> -->
          <NuxtLink 
            v-if="blok.cta" 
            :to="blok.cta.linktype == 'url' ? handleExternalLink(blok.cta.url) : localePath(getLink(blok.cta))" 
            :target="blok.cta.linktype =='url' && blok.cta?.url.indexOf('#') != 0 ? '_blank' : '_self' " 
            class="button button--border mt-[1.5rem] lg:mt-[3rem]">{{blok.cta_label || (blok.cta.story && blok.cta.story.name) }}</NuxtLink>
      </div>
   </div>
</template>

<script setup>
const props = defineProps({ blok: Object })
const animation_target = ref(null)
const { editing } = useSettings();
  const {handleAnimation}  = useUIControl()
    const {handleInternalLink,handleExternalLink} = useConfig()
const { getLayoutClasses } = useLayoutControl()
   //   const {editing} = useSettings() 
    const layout_classes = getLayoutClasses(props.blok)

 const getLink = function(link){ 

    if(link.linktype == "story"){
    
        if(link.story)return handleInternalLink(link.story.full_slug)
      }else if(link.linktype == "url"){
        return link.url;
      }
  }
//  const richText = computed(() => {

//      return renderRichText(props.blok.content)
// });
onMounted(() => {
   
   handleAnimation(animation_target,{layout:props.blok.layout,type:'pageintro',a:{...props.blok.layout.animation}})

   
})
</script>
<style lang="scss">
   .sb-page_intro{
      &__label{
         display: block;
         // @apply text-xl;
      }
      &__body{
         p{
            //   @apply text-xl;
         }
      }
      &__headline{
         h2{
            // font-size: 4rem;
         }
      }

      *:last-child{
         margin-bottom:0px;
      }
      &.text-center{
         text-align: center;
      }
   }
   
</style>